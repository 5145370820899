@charset "utf-8";

$serif-font-family: "Old Standard TT", serif;
$sans-serif-font-family: "Source Serif Pro", serif;
$base-font-family: $sans-serif-font-family;
$headline-font-family: $sans-serif-font-family;
$title-font-family: $serif-font-family;
$description-font-family: $serif-font-family;
$meta-font-family: $serif-font-family;

@import "so-simple";